import 'swiper/swiper-bundle.min.css'

import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { uniq } from 'lodash'
import React, { memo } from 'react'
import { ArrowLeft, ArrowRight } from 'react-feather'
import Fade from 'react-reveal/Fade'
import SwiperCore, {
  Autoplay,
  Controller,
  Lazy,
  Navigation,
  Thumbs,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Offer, Props as OfferProps } from './Offer'

SwiperCore.use([Autoplay, Controller, Lazy, Navigation, Thumbs])

export interface Props {
  cta?: string
  languageCode: string
  offers: OfferProps[]
}

export const OtherOffers = memo(function OtherOffers({
  cta,
  languageCode,
  offers,
}: Props) {
  if (offers.length < 1) {
    return null
  }

  return (
    <Container>
      <Fade bottom distance="3.75rem">
        <>
          <Head dial={4} row space="between" wrap>
            <Title>{useVocabularyData('other-offers', languageCode)}</Title>
            {cta ? (
              <CTA
                label={useVocabularyData('all-offers', languageCode)}
                URL={cta}
                variant="simple"
              />
            ) : null}
          </Head>
          <Slider>
            {offers ? (
              <>
                <Swiper
                  autoplay={offers.length > 1 ? { delay: 4000 } : false}
                  breakpoints={{
                    0: {
                      slidesPerView: 1.2,
                      slidesOffsetAfter: 36,
                      slidesOffsetBefore: 36,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                  }}
                  lazy
                  loop={offers.length > 1 ? true : false}
                  navigation={{
                    nextEl: `.offers-slider-next`,
                    prevEl: `.offers-slider-prev`,
                  }}
                  preloadImages={false}
                  spaceBetween={24}
                  watchSlidesProgress
                >
                  {uniq(offers).map((item, index) => (
                    <SwiperSlide key={index}>
                      <Offer {...item} />
                    </SwiperSlide>
                  ))}
                </Swiper>
                {offers.length > 1 ? (
                  <>
                    <SliderControls row space="between">
                      <Arrow dial={5} className={`offers-slider-prev`}>
                        <ArrowLeft />
                      </Arrow>
                      <Arrow dial={5} className={`offers-slider-next`}>
                        <ArrowRight />
                      </Arrow>
                    </SliderControls>
                  </>
                ) : null}
              </>
            ) : null}
          </Slider>
        </>
      </Fade>
    </Container>
  )
})

const Container = styled.section`
  max-width: 77.875rem;
  margin: 9.75rem auto 7.125rem;

  @media (max-width: 1023px) {
    margin: 7.5rem auto 6rem;
  }
`

const Head = styled(FlexBox)`
  padding: 0 3.75rem;

  @media (max-width: 767px) {
    padding: 0 1.5rem;
    text-align: center;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 2.9375rem;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const CTA = styled(Button)`
  margin-top: 0;

  @media (max-width: 1023px) {
    margin-top: 0;
  }

  @media (max-width: 767px) {
    margin: 16px auto 0;
  }
`

const Slider = styled.div`
  margin-top: 3.75rem;
  padding: 0 3.75rem;
  position: relative;
  .swiper-slide {
    height: auto;
  }

  @media (max-width: 767px) {
    padding: 0;
  }
`

const SliderControls = styled(FlexBox)`
  width: 100%;
  position: absolute;
  top: calc(50% - 6vh);
  left: 0;
  z-index: 3;
  transform: translateY(-50%);

  @media (max-width: 767px) {
    display: none;
  }
`

const Arrow = styled(FlexBox)`
  width: 2.25rem;
  height: 2.25rem;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  top: 0;
  &:first-of-type {
    left: 0;
  }
  &:last-of-type {
    right: 0;
  }
  &.swiper-button-disabled {
    opacity: 0;
  }
  svg {
    width: auto;
    height: 100%;
    stroke: ${({ theme }) => theme.colors.variants.neutralDark4};
    stroke-width: 1;
  }
`
